import axios from '@axios'

export default function coursesServices() {
  const getCourses = async (queryParams) => {
    const res = await axios.get('courses', {
      params: queryParams,
    })
    return res
  }

  const create = async (queryParams) => {
    const res = await axios.post('/course/create', queryParams)
    console.log(res.data)
    return res
  }

  const edit = async (queryParams) => {
    const res = await axios.post('/course/update', queryParams)
    return res
  }

  const del = async (queryParams) => {
    const res = await axios.post('/course/delete', queryParams)
    return res
  }

  return {
    getCourses,
    create,
    edit,
    del,
  }
}
